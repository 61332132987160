import React, { useState } from 'react';

const SubmitView = ({ onChange, onClick }) => {
  const [customerEmail, setcustomerEmail] = useState('');
  const [terms, setTerms] = useState(false);

  return (
    <div className="view submit">
      <div className="cnt-wrapper">
        <h4 className="label">Имейл адрес, на който да получиш съвета от нашите експерти</h4>


        <label htmlFor="">Твоят имейл адрес</label>

        <div className="field-wrapper">
          <input type="email" placeholder="email@example.com" required value={customerEmail} onChange={e => { setcustomerEmail(e.target.value); onChange(e.target.value); }} />
        </div>

        <p className="note">Твоят имейл адрес ще бъде използван само за отговор на запитването ти.</p>

        <div className="field-wrapper">
          <label className="checkbox-wrapper">
            <input type="checkbox" value={terms} onChange={e => setTerms(e.target.checked)} />
            <span className="text">
              Запознат съм с <a href="https://www.decathlon.bg/politika-za-zashtita-na-lichnite-danni.html" target="_blank">политиката за обработка на лични данни</a>
            </span>
          </label>
        </div>

        <div className="field-wrapper">
          <button className="btn-survey" disabled={!terms || customerEmail.length === 0} onClick={() => onClick()}>Изпрати</button>
        </div>
      </div>
    </div>
  );
}

export default SubmitView;
