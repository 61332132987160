import React from 'react';

class Modal extends React.Component {
  componentDidMount() {
    document.addEventListener('keydown', (e) => {
      if (this.modal) {
        this.handleEscKey(e);
      }
    }, false);
  }

  handleEscKey(e) {
    if (e.keyCode === 27) {
      this.props.onClose();
    }
  }

  render() {
    const { open, onClose, children } = this.props;

    return (
      <div className={`modal-ticket ${open === true ? 'modal-show' : ''}`} ref={(modal) => { this.modal = modal; }}>
        <div className="container">
          <button type="button" className="icon-close close" onClick={() => onClose()} />

          {children}
        </div>
      </div>
    );
  }
}
export default Modal;
