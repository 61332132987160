import gql from 'graphql-tag';

const PENDING_COUNT = gql`
  query ($input: DateRangeInput!) {
    pendingCount(input: $input) {
      value
    }
  }
`;

export default PENDING_COUNT;
