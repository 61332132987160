import React from 'react';
import { Query } from 'react-apollo';

import PrevPeriod from './PrevPeriod';
import Cell from '../Cell';

const withQuery = (query, dateRange, node, label, valueComponent) => (
  <Query query={query} variables={{ input: dateRange }}>
    {({ loading, error, data }) => {
      if (loading) {
        return <div>Loading...</div>;
      }

      return (
        <Cell
          valueComponent={valueComponent(data[node].value)}
          label={label}
          prevPeriod={<PrevPeriod
            query={query}
            dateRange={dateRange}
            node={node}
            currentPeriodVal={data[node].value}
          />}
        />
      );
    }}
  </Query>
);

export default withQuery;
