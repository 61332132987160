import React from 'react';
import { ImageInput, MediaManager } from '@raketa-cms/raketa-cms';

const ImagePicker = (props) => (
  <div className={"form-group" + (props.errors ? ' has-error' : '')}>
    <ImageInput mediaManager={(new MediaManager('/'))} {...props} />

    {
      props.errors &&
      <span className="help-block">{props.errors.join(', ')}</span>
    }
  </div>
);

export default ImagePicker;

