import React from 'react';
import { Img } from '@raketa-cms/raketa-cms';

const Intro = ({ onClick, ambassadorsImage, introText, introBackUrl }) => (
  <div className="intro">
    <h2 className="title">ПОСЪВЕТВАЙ СЕ С НАШ ЕКСПЕРТ</h2>

    <Img className="intro-image" src={ambassadorsImage} variant='original' />

    <div className="text">
      <p>{introText}</p>
    </div>

    {
      introBackUrl &&
      <div className="buttons-wrapper">
        <a href={introBackUrl} className="btn-survey">НАЗАД</a>
        <button className="btn-survey" onClick={() => onClick()}>ПРОДЪЛЖИ</button>
      </div>
    }

    {
      !introBackUrl &&
      <button className="btn-survey" onClick={() => onClick()}>ПРОДЪЛЖИ</button>
    }

  </div>
);

export default Intro;
