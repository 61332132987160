import React from 'react';

const QuestionLabel = ({ label, onChange }) => (
  <div className="form-group string required question_title">
    <label className="control-label string required" htmlFor="question_title">Question</label>
    <input
      className="form-control string required"
      type="text"
      id="question_title"
      value={label}
      onChange={e => onChange(e.target.value)}
    />
  </div>
);

export default QuestionLabel;
