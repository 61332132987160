import React from 'react';
import withQuery from './withQuery';
import ValueText from '../ValueText';
import RELIABILITY from '../../../../graphql/reliability';

const Reliability = ({ dateRange }) => withQuery(
  RELIABILITY,
  dateRange,
  'reliability',
  'Reliability',
  (value) => <ValueText value={`${value}%`} />
);

export default Reliability;
