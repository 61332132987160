import React from 'react';

import { TextInput } from './Inputs';

const TextBoxInput = ({ settings, onChange }) => (
  <div>
    <TextInput
      label="Placeholder"
      onChange={value => onChange(Object.assign(settings, { placeholder: value }))}
      value={settings.placeholder}
    />
  </div>
);

export default TextBoxInput;
