import React from 'react';
import { Img } from '@raketa-cms/raketa-cms';
import QuestionMeta from './QuestionMeta';
import { disableNext } from './utils';
import QuestionButtons from './QuestionButtons';

const LinkItem = ({ item }) => (
  <a href={item.link} className="item">
    <Img src={item.image} variant="box_item_image" />
    <h6 className="title">{item.title}</h6>
  </a>
);

const RadioItem = ({ item, idx, checked, onChange, onClick }) => (
  <label htmlFor={`radio-box-list${idx}`} className="item" key={idx}>
    <Img src={item.image} variant="box_item_image" />
    <h6 className="title">{item.title}</h6>

    <input
      type="radio"
      id={`radio-box-list${idx}`}
      onChange={e => onChange(e.target.value)}
      value={item.title}
      name="box_list_radio"
      checked={checked}
      onClick={onClick}
    />

    <div className="icon-check" />
    <div className="icon-tick" />
  </label>
);

class BoxListView extends React.Component {
  constructor(props) {
    super(props);

    const { questionSettings } = this.props;

    this.state = {
      isNextDisabled: disableNext(this.props.question, questionSettings),
      checkedItem: questionSettings() ? questionSettings().answer : '',
    };
  }

  handleChange(value) {
    const { onChange, question } = this.props;

    this.setState({ isNextDisabled: false });

    onChange({
      settings: {
        question_label: question.label,
        answer: value,
        question_id: question.id
      }
    });
  }

  render() {
    const { isNextDisabled, checkedItem } = this.state;
    const {
      question,
      nextQuestion,
      prevQuestion,
      questionsCount,
      isGatewaySurvey,
      prevSurveyUrl
    } = this.props;

    return (
      <div className="view box-list">
        <div className="cnt-wrapper">
          <QuestionMeta
            questionOrd={question.ord}
            questionsCount={questionsCount}
            isGatewaySurvey={isGatewaySurvey}
          />

          <h4 className="label">{question.label}</h4>
          <div className="items-wrapper">
            {question.settings.map((item, idx) => (
              item.link ?
                <LinkItem key={idx} idx={idx} item={item} />
                :
                <RadioItem
                  key={idx}
                  idx={idx}
                  item={item}
                  checked={checkedItem ? checkedItem === item.title : false}
                  onChange={value => this.handleChange(value)}
                  onClick={() => this.setState({ checkedItem: item.title })}
                />
            ))}
          </div>

          <QuestionButtons
            prevSurveyUrl={prevSurveyUrl}
            prevQuestion={prevQuestion}
            isNextDisabled={isNextDisabled}
            nextQuestion={nextQuestion}
          />
        </div>
      </div>
    );
  }
}

export default BoxListView;
