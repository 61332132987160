import React from 'react';
import withQuery from './withQuery';
import ValueText from '../ValueText';
import TOTAL_VALID_COUNT from '../../../../graphql/totalValidCount';

const TotalValidCount = ({ dateRange }) => withQuery(
  TOTAL_VALID_COUNT,
  dateRange,
  'totalValidCount',
  'Questions',
  (value) => <ValueText value={value} />
);

export default TotalValidCount;
