import React from 'react';
import ReactDatePicker from 'react-datepicker';

const DatePicker = ({ fromDate, toDate, onChange }) => (
  <div className="date-picker">
    <div className="calendar-wrapper">
      <i className="icon-calendar" />
      <ReactDatePicker
        selected={fromDate}
        selectsStart
        startDate={fromDate}
        endDate={toDate}
        onChange={(date) => onChange({ fromDate: date })}
        className="start-date"
      />
    </div>

    <div className="calendar-wrapper">
      <i className="icon-calendar" />
      <ReactDatePicker
        selected={toDate}
        selectsEnd
        startDate={fromDate}
        endDate={toDate}
        onChange={(date) => onChange({ toDate: date })}
        className="end-date"
      />
    </div>
  </div>
);

export default DatePicker;
