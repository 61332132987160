import React from 'react';
import ProductsList from './ProductsList';
import { isValid } from 'ipaddr.js';

class TicketProcess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      primaryProducts: [],
      accessoryProducts: [],
      isSubmitDisabled: true,
    };
  }

  buildProductListKey(type) {
    return `${type}Products`;
  }

  handleProductsAdd(products, type) {
    const prductListKey = this.buildProductListKey(type);
    const productsList = this.state[prductListKey];

    const newProducts = [...products];
    this.setState({ [prductListKey]: newProducts }, () => this.setSubmit());
  }

  handleProductRemove(productIdx, type) {
    const prductListKey = this.buildProductListKey(type);
    const productsList = this.state[prductListKey];

    const newProducts = [...productsList.slice(0, productIdx), ...productsList.slice(productIdx + 1)];
    this.setState({ [prductListKey]: newProducts }, () => this.setSubmit());
  }

  isProductsValid() {
    const { primaryProducts, accessoryProducts } = this.state;

    const isPrimaryProductsValid = primaryProducts.every(product => product.isValid === true);
    const isAccessoryProductsValid = accessoryProducts.every(product => product.isValid === true);

    return isPrimaryProductsValid == false || isAccessoryProductsValid == false;
  }

  setSubmit() {
    this.setState({
      isSubmitDisabled: this.isProductsValid() || this.state.primaryProducts.length == 0 || this.state.accessoryProducts.length == 0
    });
  }

  render() {
    const { ticketId, errors } = this.props;
    const { primaryProducts, accessoryProducts, isSubmitDisabled } = this.state;

    return (
      <div>
        {errors && <div className="red-text">{errors.join(', ')}</div>}

        <ProductsList
          type="primary"
          title="Primary Products"
          ticketId={ticketId}
          products={primaryProducts}
          handleProductsAdd={(products, type) => this.handleProductsAdd(products, type)}
          handleProductRemove={(productIdx, type) => this.handleProductRemove(productIdx, type)}
          handleProductChange={() => this.setSubmit()}
        />

        <br />

        <ProductsList
          type="accessory"
          title="Accessoires"
          ticketId={ticketId}
          products={accessoryProducts}
          handleProductsAdd={(products, type) => this.handleProductsAdd(products, type)}
          handleProductRemove={(productIdx, type) => this.handleProductRemove(productIdx, type)}
          handleProductChange={() => this.setSubmit()}
        />

        <br />

        <button type="submit" disabled={isSubmitDisabled} className="waves-effect waves-light btn">Send Recommendation</button>
      </div>
    );
  }
};

export default TicketProcess;
