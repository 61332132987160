import React from 'react'
import Modal from '../Modal';
import ProductPreview from './ProductPreview';

class TicketModal extends React.Component {
  handleAddSelectedProducts() {
    const { onClose, onAddProducts, selectedProducts } = this.props;

    onAddProducts(selectedProducts);
    onClose();
  }

  handlePressEnter(event) {
    if (event.which === 13) {
      event.preventDefault();
      this.props.onSubmit();
    }
  }

  renderPreview() {
    const { products, handleProductAdd, handleProductRemove, selectedProducts } = this.props;

    if (products.length <= 0) return;

    return (
      <div className="products-list">
        {products.map((product, idx) =>
          <ProductPreview
            key={idx}
            product={product}
            onClick={isSelected => isSelected ? handleProductAdd(product) : handleProductRemove(product)}
            selectedProducts={selectedProducts}
          />)}
      </div>
    );
  }

  render() {
    const {
      isModalOpen,
      onClose,
      q,
      products,
      onSearchChange,
      onSubmit,
      onAddProducts,
      isLoaderVisible,
    } = this.props;

    return (
      <Modal open={isModalOpen} onClose={() => onClose()}>
        <div className="row">
          <h5 className="title">Select products</h5>
          <div className="search-wrapper">
            <input
              type="text"
              className="validate"
              placeholder="Start typing products"
              value={q}
              onChange={(e) => onSearchChange(e.target.value)}
              onKeyPress={e => this.handlePressEnter(e)}
            />

            <button type="button" className="waves-effect waves-light btn" onClick={() => onSubmit()}>Search</button>
          </div>
        </div>

        {isLoaderVisible ? <div className="loader-spinner"></div> : this.renderPreview()}

        <br />

        <div className="add-button-wrapper">
          <div className="container">
            <button type="button" className="waves-effect waves-light btn" onClick={() => this.handleAddSelectedProducts()}>Add selected</button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default TicketModal;

