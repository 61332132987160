import React from 'react';

const Cell = ({ valueComponent, label, prevPeriod }) => (
  <div className="cell">
    {valueComponent}
    {label && <h5 className="label">{label}</h5>}
    {prevPeriod}
  </div>
);

export default Cell;
