import gql from 'graphql-tag';

const AMBASSADORS_COUNT = gql`
  query ($input: DateRangeInput!) {
    ambassadorsCount(input: $input) {
      value
    }
  }
`;

export default AMBASSADORS_COUNT;
