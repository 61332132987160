import React from 'react';
import Autocomplete from 'react-autocomplete';
import jQuery from 'jquery';

class AutocompleteWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      q: props.q || '',
      selected: props.selected,
    };
  }

  handleProductSearch(q) {
    const { source } = this.props;

    this.setState({ q, products: [] }, () => {
      if (q.length < 3) return;

      jQuery.get(source, { q }, (products) => {
        this.setState({ products });
      }, 'json');
    });
  }

  handleGoToProduct(selected) {
    this.setState({ selected, q: selected.title })
  }

  render() {
    const { source, name, placeholder } = this.props;
    const { products, q, selected } = this.state;

    const menuStyle = {
      boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
      background: '#000000',
      position: 'absolute',
      top: 'auto',
      overflow: 'auto',
      left: '0',
    };

    return (
      <div className="autocomplete">
        <Autocomplete
          getItemValue={item => item.title}
          items={products}
          renderItem={(item, isHighlighted) =>
            <div key={item.id} style={{ padding: '.75em', cursor: 'pointer', background: isHighlighted ? 'lightgray' : '#fff' }}>
              {item.title}
            </div>
          }
          value={q}
          onChange={e => this.handleProductSearch(e.target.value)}
          onSelect={(productName, product) => this.handleGoToProduct(product)}
          inputProps={{ placeholder }}
          wrapperStyle={{ display: 'inline-block', width: '100%' }}
          menuStyle={menuStyle}
        />

        <input type="hidden" name={name} value={selected ? selected.id : ''} />
      </div>
    );
  }
}

export default AutocompleteWrapper;
