import React from 'react'
import jQuery from 'jquery';
import TicketModal from './TicketModal';

class ProductPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      q: '',
      products: [],
      selectedProducts: this.props.selectedProducts || [],
      isLoaderVisible: false,
    }
  }

  handleProductSearch() {
    const { selectedProducts } = this.state;

    selectedProducts.length < 1 ? this.submitQuery() : this.submitConfirm();
  }

  submitConfirm() {
    if (confirm('Are you sure, this will delete all selected products?')) {
      this.setState({ selectedProducts: [], products: [] });
      this.submitQuery();
    }
  }

  submitQuery() {
    const { q, products, isLoaderVisible } = this.state;

    this.setState({ isLoaderVisible: true });

    jQuery.get('/tickets/products.json?', { q }, (products) => {
      this.setState({ products });
    }, 'json').done(() => this.setState({ isLoaderVisible: false }));
  }

  handleProductAdd(product) {
    const { selectedProducts } = this.state;
    const newProducts = [...selectedProducts, product];

    this.setState({ selectedProducts: newProducts });
  }

  handleProductRemove(product) {
    const { selectedProducts } = this.state;
    const productIdx = selectedProducts.indexOf(product);
    const newProducts = [...selectedProducts.slice(0, productIdx), ...selectedProducts.slice(productIdx + 1)];

    this.setState({ selectedProducts: newProducts });
  }

  handleOpenModal() {
    const { isModalOpen } = this.state;

    this.setState({ isModalOpen: true, selectedProducts: this.props.selectedProducts });
  }

  render() {
    const { isModalOpen, q, products, selectedProducts, isLoaderVisible } = this.state;

    return (
      <div>
        <button type="button" className="waves-effect waves-light btn" onClick={() => this.handleOpenModal() }>Select products</button>

        <TicketModal
          isModalOpen={isModalOpen}
          onClose={() => this.setState({ isModalOpen: false })}
          q={q}
          onSearchChange={value => this.setState({ q: value })}
          onSubmit={() => this.handleProductSearch()}
          products={products}
          handleProductAdd={(product) => this.handleProductAdd(product)}
          handleProductRemove={(product) => this.handleProductRemove(product)}
          selectedProducts={selectedProducts}
          onAddProducts={this.props.onAddProducts}
          isLoaderVisible={isLoaderVisible}
        />
      </div>
    );
  }
}

export default ProductPicker;
