import React from 'react';
import toCurrency from '../../helpers/toCurrency';

class ProductPreview extends React.Component {
  isSelected() {
    return this.props.selectedProducts.some(currentProduct => this.props.product.id == currentProduct.id);
  }

  render() {
    const { product, onClick } = this.props;
    const isSelected = this.isSelected();

    return (
      <div className={`product-preview ${isSelected ? 'selected' : ''}`} onClick={() => onClick(!this.isSelected())}>
        {isSelected ? <i className="icon-check" /> : null}
        <img src={product.image_url} alt="" />
        <h6 className="title">{product.title}</h6>
        <p className="price">{toCurrency(product.price)}</p>
      </div>
    );
  }
}

export default ProductPreview;