import gql from 'graphql-tag';

const RATED = gql`
  query ($input: DateRangeInput!) {
    rated(input: $input) {
      value
    }
  }
`;

export default RATED;
