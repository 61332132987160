import gql from 'graphql-tag';

const RELIABILITY = gql`
  query ($input: DateRangeInput!) {
    reliability(input: $input) {
      value
    }
  }
`;

export default RELIABILITY;
