import React from 'react';

const raitingClasses = {
  0: 'zero-stars',
  1: 'one-star',
  2: 'two-stars',
  3: 'three-stars',
  4: 'four-stars',
  5: 'five-stars',
};

const ValueRaiting = ({ value }) => (
  <div className="raiting">
    <i className={raitingClasses[value]} />
  </div>
);

export default ValueRaiting;
