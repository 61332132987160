import React from 'react';
import QuestionMeta from './QuestionMeta';
import { disableNext } from './utils';
import QuestionButtons from './QuestionButtons';

class SimpleListView extends React.Component {
  constructor(props) {
    super(props);

    const { questionSettings } = this.props;

    this.state = {
      isNextDisabled: disableNext(this.props.question, questionSettings),
      checkedItem: questionSettings() ? questionSettings().answer : '',
    };
  }

  handleChange(value) {
    const { onChange, question } = this.props;

    this.setState({ isNextDisabled: false });

    onChange({
      question_label: question.label,
      answer: value,
      question_id: question.id
    });
  }

  render() {
    const { isNextDisabled, checkedItem } = this.state;
    const {
      question,
      nextQuestion,
      prevQuestion,
      questionsCount,
      isGatewaySurvey,
      prevSurveyUrl
    } = this.props;

    return (
      <div className="view simple-list">
        <div className="cnt-wrapper">
          <QuestionMeta
            questionOrd={question.ord}
            questionsCount={questionsCount}
            isGatewaySurvey={isGatewaySurvey}
          />

          <h4 className="label">{question.label}</h4>
          <div className="items-wrapper">
            {question.settings.map((item, idx) => (
              <label htmlFor={`radio-simple-list${idx}`} key={idx}>

                <input
                  type="radio"
                  id={`radio-simple-list${idx}`}
                  onChange={e => this.handleChange(e.target.value)}
                  value={item.title}
                  name="simple_list_radio"
                  checked={checkedItem ? checkedItem === item.title : false}
                  onClick={() => this.setState({ checkedItem: item.title })}
                />

                <div className="icon-check" />
                <div className="icon-tick" />

                <h6 className="title">{item.title}</h6>
              </label>
            ))}
          </div>

          <QuestionButtons
            prevSurveyUrl={prevSurveyUrl}
            prevQuestion={prevQuestion}
            isNextDisabled={isNextDisabled}
            nextQuestion={nextQuestion}
          />
        </div>
      </div>
    );
  }
}


export default SimpleListView;
