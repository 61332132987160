import gql from 'graphql-tag';

const REJECTED_COUNT = gql`
  query ($input: DateRangeInput!) {
    rejectedCount(input: $input) {
      value
    }
  }
`;

export default REJECTED_COUNT;
