import React from 'react';
import jQuery from 'jquery';

const ambassadorStatus = (isAvailable) => isAvailable ? 'available' : 'unavailable'
const isAmbassadorAvailable = (status) => status === 'available'

class IsAmbassadorAvailable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAvailable: isAmbassadorAvailable(this.props.status),
    };
  }

  handleCheckboxChange() {
    const { isAvailable } = this.state;

    this.setState({ isAvailable: !isAvailable }, () => this.sendAvailability());
  }

  sendAvailability() {
    const { isAvailable } = this.state;
    const { authenticity_token, profile_id } = this.props;

    jQuery.ajax({
      type: 'PATCH',
      url: '/tickets/profile',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({
        authenticity_token,
        _method: 'patch',
        ambassador: { status: ambassadorStatus(isAvailable), id: profile_id }
      }),
    });
  }

  render() {
    const { isAvailable } = this.state;

    return (
      <div className="switch is-ambassador-available">
        <label>
          <input type="checkbox" onChange={() => this.handleCheckboxChange()} checked={isAvailable} id="is-ambassador-avalable" />
          <span className="lever"></span>
          <span className={`text ${isAvailable ? 'primary-color' : ''}`}>I am available</span>
        </label>
      </div>
    );
  }
}

export default IsAmbassadorAvailable;
