import React from 'react';

const NumberInput = ({ label, value, placeholder, onChange }) => (
  <div className="form-group">
    <label className="control-label">{label}</label>

    <input
      type="number"
      className="form-control"
      defaultValue={value}
      placeholder={placeholder}
      onChange={value => onChange(value)}
    />
  </div>
);

const TextInput = ({ label, value, placeholder, onChange }) => (
  <div className="form-group">
    <label className="control-label">{label}</label>

    <input
      type="text"
      className="form-control"
      placeholder={placeholder}
      defaultValue={value}
      onChange={e => onChange(e.target.value)}
    />
  </div>
);

export { NumberInput, TextInput };
