import React from 'react';
import { NumberInput, TextInput } from './Inputs';

const RangeInput = ({ settings, onChange }) => (
  <div>
    <NumberInput
      label="Min"
      onChange={e => onChange(Object.assign(settings, { min: parseInt(e.target.value) }))}
      value={settings.min}
    />

    <NumberInput
      label="Max"
      onChange={e => onChange(Object.assign(settings, { max: parseInt(e.target.value) }))}
      value={settings.max}
    />

    <TextInput
      label="Unit"
      onChange={value => onChange(Object.assign(settings, { unit: value }))}
      value={settings.unit}
    />
  </div>
);

export default RangeInput;
