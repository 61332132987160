import React from 'react';
import { Query } from 'react-apollo';
import moment from 'moment';

const prevDatesRange = ({ fromDate, toDate }) => {
  const fd = new moment(fromDate);
  const ed = new moment(toDate);

  const daysRange = moment.duration(ed.diff(fd)).as('days') + 1;

  const prevFromDate = fd.clone().subtract(daysRange, 'days');
  const prevToDate = ed.clone().subtract(daysRange, 'days');

  return {
    fromDate: prevFromDate.format('YYYY-MM-DD'),
    toDate: prevToDate.format('YYYY-MM-DD'),
  };
};

const round = (value, decimals) => Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);

const calcPeriodRatio = (currentVal, prevVal) => {
  let ratio = 0;

  if (prevVal && prevVal != 0.0) {
    ratio = round(((currentVal - prevVal) / prevVal) * 100, 2);
  }

  return ratio;
};

const PrevPeriod = ({ query, dateRange, node, currentPeriodVal }) => (
  <Query query={query} variables={{ input: prevDatesRange(dateRange) }}>
    {({ loading, error, data }) => {
      if (loading) {
        return <div>Loading...</div>;
      }

      return (<p className="prev-period">{calcPeriodRatio(currentPeriodVal, data[node].value)}%</p>);
    }}
  </Query>
);

export default PrevPeriod;
