import React from 'react';
import withQuery from './withQuery';
import ValueText from '../ValueText';
import REQUESTS_COUNT from '../../../../graphql/requestsCount';

const RequestsCount = ({ dateRange }) => withQuery(
  REQUESTS_COUNT,
  dateRange,
  'requestsCount',
  'Total Requests',
  (value) => <ValueText value={value} />
);

export default RequestsCount;
