import gql from 'graphql-tag';

const TICKETS_RATING = gql`
  query ($input: DateRangeInput!) {
    ticketsRating(input: $input) {
      value
    }
  }
`;

export default TICKETS_RATING;
