import gql from 'graphql-tag';

const REQUESTS_COUNT = gql`
  query ($input: DateRangeInput!) {
    requestsCount(input: $input) {
      value
    }
  }
`;

export default REQUESTS_COUNT;
