import React from 'react';
import withQuery from './withQuery';
import ValueText from '../ValueText';
import REJECTED_COUNT from '../../../../graphql/rejectedCount';

const RejectedCount = ({ dateRange }) => withQuery(
  REJECTED_COUNT,
  dateRange,
  'rejectedCount',
  'Rejected',
  (value) => <ValueText value={value} />
);

export default RejectedCount;
