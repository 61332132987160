import React from 'react';

const closeLightbox = () => {
  window.parent.postMessage({ action: 'closeSurveyPopup' }, '*');
}

const FinishView = () => (
  <div className="intro">
    <h2 className="title">БЛАГОДАРИМ ТИ ЗА ЗАПИТВАНЕТО!</h2>

    <div className="intro-image" />

    <div className="text">
      <p>Най-подходящият експерт на Decathlon ще отговори на въпроса ти и ще изпрати своята препоръка в рамките на 24 часа.</p>
    </div>
    <button className="btn-survey" onClick={() => closeLightbox()}>ОБРАТНО КЪМ САЙТА</button>
  </div>
);

export default FinishView;
