import React from 'react';
import toCurrency from '../../helpers/toCurrency';

class Product extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      note: '',
      isNoteValid: true,
    }
  }

  handleNoteChange(value) {
    const { note, isNoteValid } = this.state;

    this.setState({ note: value }, () => {
      if (value.length < 1) {
        this.setState({ note: value, isNoteValid: false });
        this.props.onChange(false);
      } else {
        this.setState({ note: value, isNoteValid: true });
        this.props.onChange(true);
      }
    });
  }

  render() {
    const { product, type, ticketId, onRemove } = this.props;
    const { note, isNoteValid } = this.state;

    return (
      <div className="product">
        <a href={product.url} className="image-wrapper" target="_blank">
          <img src={product.image_url} alt={product.title} />
        </a>

        <div className="info">
          <h6>{product.title}</h6>
          <p className="grey-text">{toCurrency(product.price)} <a href={product.url} className="product-link" target="_blank">See on website</a></p>
          <textarea
            placeholder="Add note"
            className="materialize-textarea"
            name=""
            id=""
            cols="30"
            rows="10"
            onChange={e => this.handleNoteChange(e.target.value)}
            value={note}
          />
          {!isNoteValid && <span className="input-error red-text">Note can't be blank</span>}
          <br/>

          <a href="#" className="red-text" onClick={(e) => { e.preventDefault(); onRemove(); }} >Remove</a>
        </div>


        <input type="hidden" name={`recommended_products[recommended_products][]recommended_product[product_id]`} value={product.id} />
        <input type="hidden" name={`recommended_products[recommended_products][]recommended_product[product_type]`} value={type} />
        <input type="hidden" name={`recommended_products[recommended_products][]recommended_product[ticket_id]`} value={ticketId} />
        <input type="hidden" name={`recommended_products[recommended_products][]recommended_product[note]`} value={note} />
      </div>
    );
  }
}

export default Product;
