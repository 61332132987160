import React from 'react';
import withQuery from './withQuery';
import ValueText from '../ValueText';
import TOTAL_RATINGS from '../../../../graphql/totalRatings';

const TotalRatings = ({ dateRange }) => withQuery(
  TOTAL_RATINGS,
  dateRange,
  'totalRatings',
  'Total Ratings',
  (value) => <ValueText value={value} />
);

export default TotalRatings;
