import React from 'react';
import withQuery from './withQuery';
import ValueRaiting from '../ValueRaiting';
import TICKETS_RATING from '../../../../graphql/ticketsRating';

const TicketsRating = ({ dateRange }) => withQuery(
  TICKETS_RATING,
  dateRange,
  'ticketsRating',
  'AVG. Raiting',
  (value) => <ValueRaiting value={value} />
);

export default TicketsRating;
