import React from 'react';
import withQuery from './withQuery';
import ValueText from '../ValueText';
import RATED from '../../../../graphql/rated';

const Rated = ({ dateRange }) => withQuery(
  RATED,
  dateRange,
  'rated',
  'Rated',
  (value) => <ValueText value={`${value}%`} />
);

export default Rated;
