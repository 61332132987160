import React from 'react';
import { ListInput, TextInput } from '@raketa-cms/raketa-cms';
import ImagePicker from '../ImagePicker';

const BoxListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      onChange={image => onChangeItem('image', image)}
      image={settings.image}
    />

    <TextInput
      label="Title"
      onChange={value => onChangeItem('title', value)}
      value={settings.title}
    />

    <TextInput
      label="Link"
      onChange={value => onChangeItem('link', value)}
      value={settings.link}
    />
  </div>
);

const BoxList = ({ settings, onChange }) => (
  <div className="form-group">
    <label className="control-label">Answers</label>
    <ListInput
      listItem={(itemSettings, onChangeItem) =>
        <BoxListItem settings={itemSettings} onChangeItem={onChangeItem} />}
      onChangeList={(_, items) => onChange(items)}
      items={settings}
    />
  </div>
);

export default BoxList;
