import React from 'react';
import TicketAutocomplete from './TicketAutocomplete';
import Product from './Product';
import ProductPicker from './ProductPicker';


const ProductsList = ({ type, title, products, ticketId, handleProductsAdd, handleProductRemove, handleProductChange }) => {
  return (
    <div>
      <h5>{title}</h5>
      <br/>
      <div className="spacing-bottom">
        <ProductPicker onAddProducts={newProducts => handleProductsAdd(newProducts, type)} selectedProducts={products} />
      </div>

      {products &&
        products.map((product, idx) =>
          <Product
            key={idx}
            ticketId={ticketId}
            product={product}
            type={type}
            onRemove={() => handleProductRemove(idx, type)}
            onChange={isValid => { product.isValid = isValid; handleProductChange(); }}
          />)
      }
    </div>
  )
};

export default ProductsList;
