import React from 'react';
import withQuery from './withQuery';
import ValueText from '../ValueText';
import ANSWERS_COUNT from '../../../../graphql/answersCount';

const AnswersCount = ({ dateRange }) => withQuery(
  ANSWERS_COUNT,
  dateRange,
  'answersCount',
  'Answers',
  (value) => <ValueText value={value} />
);

export default AnswersCount;
