import React from 'react';

import { ApolloProvider } from 'react-apollo';
import createApolloClient from '../../../graphql/createApolloClient';

import DatePicker from './DatePicker';

import AmbassadorsCount from './stats/AmbassadorsCount';
import TicketsResolutionTime from './stats/TicketsResolutionTime';
import Reliability from './stats/Reliability';
import TicketsRating from './stats/TicketsRating';
import TotalRatings from './stats/TotalRatings';

import RequestsCount from './stats/RequestsCount';
import AnswersCount from './stats/AnswersCount';
import RejectedCount from './stats/RejectedCount';
import PendingCount from './stats/PendingCount';
import TotalValidCount from './stats/TotalValidCount';

import Rated from './stats/Rated';

import Row from './Row';

import {
  startOfWeek,
  startOfPreviousWeek,
  endOfPreviousWeek,
  startOfMonth,
  startPreviousOfMonth,
  endPreviousOfMonth,
  daysDiff
} from '../../helpers/dateHelper';

const filterClick = (event, onChange, fromDate, toDate) => {
  event.preventDefault;
  onChange({ fromDate: fromDate, toDate: toDate });
};

const Filters = ({ onDateChange }) => (
  <div className="shortcuts">
    <a href="#" onClick={(event) => filterClick(event, onDateChange, startOfWeek(new Date), new Date)}>This Week</a>
    <a href="#" onClick={(event) => filterClick(event, onDateChange, startOfPreviousWeek(new Date), endOfPreviousWeek(new Date))}>Last Week</a>
    <a href="#" onClick={(event) => filterClick(event, onDateChange, startOfMonth(new Date), new Date)}>This Month</a>
    <a href="#" onClick={(event) => filterClick(event, onDateChange, startPreviousOfMonth(new Date), endPreviousOfMonth(new Date))}>Last Month</a>
  </div >
);

const apolloWrapper = ({ client, ...props }) => ({ component: Component }) => (
  <ApolloProvider client={client}>
    <Component {...props} />
  </ApolloProvider>
);

class AnalyticsWithApollo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dateRange: {
        fromDate: new Date(Date.now()),
        toDate: new Date(Date.now()),
      },
    };

    this.onDateChange = this.onDateChange.bind(this);
  }


  onDateChange(date) {
    const dateRange = { dateRange: { ...this.state.dateRange, ...date } };

    this.setState(dateRange);
  }

  toParams({ fromDate, toDate }) {

    return {
      fromDate: fromDate.toISOString(),
      toDate: toDate.toISOString()
    };
  }

  render() {
    const { dateRange } = this.state;

    const TicketsClient = apolloWrapper({ client: this.props.ticketsClient, dateRange: this.toParams(dateRange) });
    const numberOfDays = daysDiff(dateRange.fromDate, dateRange.toDate) + 1;

    return (
      <div className="dashboard">
        <div className="folters-row">
          <DatePicker
            className="filters-item"
            fromDate={this.state.dateRange.fromDate}
            toDate={this.state.dateRange.toDate}
            onChange={this.onDateChange}
          />

          <Filters className="filters-item" onDateChange={this.onDateChange} />
        </div>

        <p>Showing stats for <span className="accent-text">{numberOfDays} {numberOfDays > 1 ? 'days' : 'day'}</span></p>

        <Row title="General">
          <TicketsClient component={AmbassadorsCount} />
          <TicketsClient component={TicketsResolutionTime} />
          <TicketsClient component={Reliability} />
          <TicketsClient component={TicketsRating} />
        </Row>

        <Row title="Reliability">
          <TicketsClient component={RequestsCount} />
          <TicketsClient component={PendingCount} />
          <TicketsClient component={AnswersCount} />
          <TicketsClient component={RejectedCount} />
        </Row>

        <Row title="Raiting">
          <TicketsClient component={TotalRatings} />
          <TicketsClient component={TicketsRating} />
          <TicketsClient component={Rated} />
        </Row>

        <Row title="Conversions">
          <TicketsClient component={TotalValidCount} />
          <TicketsClient component={AnswersCount} />
          <TicketsClient component={PendingCount} />
          <TicketsClient component={TotalRatings} />
        </Row>
      </div>
    );
  }
}

const Analytics = ({ ticketsUri }) => {
  return <AnalyticsWithApollo
    ticketsClient={createApolloClient(ticketsUri)}
  />
};

export default Analytics;
