import React from 'react';
import moment from 'moment';
import withQuery from './withQuery';
import ValueText from '../ValueText';
import TICKETS_RESOLUTION_TIME from '../../../../graphql/ticketsResolutionTime';

const formatTime = (seconds) => {
  const duration = moment.duration(seconds, 'seconds');
  return Math.floor(duration.asHours()) + moment.utc(duration.asMilliseconds()).format(":mm:ss");
}

const TicketsResolutionTime = ({ dateRange }) => withQuery(
  TICKETS_RESOLUTION_TIME,
  dateRange,
  'ticketsResolutionTime',
  'Avg. time to answer',
  (value) => <ValueText value={formatTime(value)} />
);

export default TicketsResolutionTime;
