import { createHttpLink } from 'apollo-link-http';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';

const createApolloClient = (host, authToken) => {
  const httpLink = createHttpLink({ uri: host, credentials: 'same-origin' });

  let apolloParams = { cache: new InMemoryCache(), link: httpLink };

  if (authToken) {
    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${authToken}`,
      }
    }));

    apolloParams.link = authLink.concat(apolloParams.link);
  }

  return new ApolloClient(apolloParams);
};

export default createApolloClient;
