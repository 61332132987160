import React from 'react';
import withQuery from './withQuery';
import ValueText from '../ValueText';
import AMBASSADORS_COUNT from '../../../../graphql/ambassadorsCount';

const AmbassadorsCount = ({ dateRange }) => withQuery(
  AMBASSADORS_COUNT,
  dateRange,
  'ambassadorsCount',
  'New ambassadors',
  (value) => <ValueText value={value} />
);

export default AmbassadorsCount;
