import React from 'react';
import BoxList from './BoxList';
import SimpleList from './SimpleList';
import QuestionSelect from './QuestionSelect';
import QuestionLabel from './QuestionLabel';
import RangeInput from './RangeInput';
import SingleLineInput from './SingleLineInput';
import TextBoxInput from './TextBoxInput';

const QuestionInput = ({ InputComponent, settings, onChange }) => {
  if (!InputComponent) return null;

  return (<InputComponent settings={settings} onChange={onChange} />);
};

const areSettingsPresent = (settings) => {
  if (settings.length === 0) { return false; }

  return settings.length > 0 || settings !== null || typeof (settings) !== "undefined"
};

class Question extends React.Component {
  static types = {
    box_list: {
      component: BoxList,
      defaultSettings: [{ id: 1, title: '', image: '' }]
    },
    simple_list: {
      component: SimpleList,
      defaultSettings: [{ id: 1, title: '' }]
    },
    text: {
      component: TextBoxInput,
      defaultSettings: { placeholder: '' }
    },
    single_line: {
      component: SingleLineInput,
      defaultSettings: { placeholder: '' }
    },
    range: {
      component: RangeInput,
      defaultSettings: { min: 0, max: 2000, unit: '' }
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      label: props.label || '',
      questionType: props.question_type || 'box_list',
      settings: areSettingsPresent(props.settings) ? props.settings : Question.types.box_list.defaultSettings,
    };
  }

  settingsFactory(value) {
    const { questionType } = this.state;
    const type = value ? value : questionType;

    return Question.types[type].defaultSettings || [];
  }

  handleTypeChange(value) {
    const newValue = value;

    this.setState({ questionType: newValue, settings: this.settingsFactory(value) })
  }

  render() {
    const { label, questionType, settings } = this.state;
    const { question_types } = this.props;
    const questionInputType = Question.types[questionType];

    return (
      <div>
        <QuestionLabel label={label} onChange={value => this.setState({ label: value })} />

        <QuestionSelect
          questionTypes={question_types}
          questionType={questionType}
          onChange={value => this.handleTypeChange(value)}
        />

        <QuestionInput
          InputComponent={questionInputType.component}
          settings={settings}
          onChange={settings => this.setState({ settings })}
        />

        <input type="hidden" name="question[settings]" value={JSON.stringify(settings)} />
        <input type="hidden" name="question[label]" value={label} />
        <input type="hidden" name="question[question_type]" value={questionType} />
      </div>
    );
  }
}

export default Question;
