import React from 'react';

export default ({ prevSurveyUrl, prevQuestion, isNextDisabled, nextQuestion }) => (
  <div className="buttons-wrapper">
    {
      prevSurveyUrl ?
        <a href={prevSurveyUrl} className="btn-survey" >Назад</a> :
        <button className="btn-survey" onClick={() => prevQuestion()}>Назад</button>
    }

    <button className="btn-survey" disabled={isNextDisabled} onClick={() => nextQuestion()}>Продължи</button>
  </div>
);
