import React from 'react';

const QuestionMeta = ({ questionOrd, questionsCount, isGatewaySurvey }) => {
  if (isGatewaySurvey) { return <p className="meta"><br /></p> }

  if (questionOrd === questionsCount) {
    return <p className="meta">Последен въпрос</p>
  } else {
    return <p className="meta">{`Въпрос ${questionOrd} от ${questionsCount}`}</p>
  }
}

export default QuestionMeta;
