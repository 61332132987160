import gql from 'graphql-tag';

const TOTAL_VALID_COUNT = gql`
  query ($input: DateRangeInput!) {
    totalValidCount(input: $input) {
      value
    }
  }
`;

export default TOTAL_VALID_COUNT;
