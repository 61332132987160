import React from 'react';
import withQuery from './withQuery';
import ValueText from '../ValueText';
import PENDING_COUNT from '../../../../graphql/pendingCount';

const PendingCount = ({ dateRange }) => withQuery(
  PENDING_COUNT,
  dateRange,
  'pendingCount',
  'Pending',
  (value) => <ValueText value={value} />
);

export default PendingCount;
