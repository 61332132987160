import moment from 'moment';

const startOfWeek = (date) => moment(date).startOf('isoWeek').toDate()
const startOfPreviousWeek = (date) => moment(date).subtract(1, 'weeks').startOf('isoWeek').toDate()
const endOfPreviousWeek = (date) => moment(date).subtract(1, 'weeks').endOf('isoWeek').toDate()

const startOfMonth = (date) => moment(date).startOf('month').toDate()
const startPreviousOfMonth = (date) => moment(date).subtract(1, 'months').startOf('month').toDate()
const endPreviousOfMonth = (date) => moment(date).subtract(1, 'months').endOf('month').toDate()

const daysDiff = (startDate, endDate) => moment(endDate).diff(moment(startDate), 'days')

export {
  startOfWeek,
  startOfPreviousWeek,
  endOfPreviousWeek,
  startOfMonth,
  startPreviousOfMonth,
  endPreviousOfMonth,
  daysDiff
};
