import gql from 'graphql-tag';

const TICKETS_RESOLUTION_TIME = gql`
  query ($input: DateRangeInput!) {
    ticketsResolutionTime(input: $input) {
      value
    }
  }
`;

export default TICKETS_RESOLUTION_TIME;
