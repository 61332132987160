import React from 'react';
import QuestionMeta from './QuestionMeta';
import { disableNext } from './utils';
import QuestionButtons from './QuestionButtons';

class SingleLineTextView extends React.Component {
  constructor(props) {
    super(props);

    const { questionSettings } = this.props;

    this.state = {
      isNextDisabled: disableNext(this.props.question, questionSettings),
      value: questionSettings() ? questionSettings().answer : ''
    };
  }

  handleChange(value) {
    const { onChange, question } = this.props;

    if (value) {
      this.setState({ isNextDisabled: false, value });
    } else {
      this.setState({ isNextDisabled: question.required, value });
    }

    onChange({
      question_label: question.label,
      answer: value,
      question_id: question.id
    });
  }

  render() {
    const { isNextDisabled, value } = this.state;
    const {
      question,
      nextQuestion,
      prevQuestion,
      questionsCount,
      isGatewaySurvey,
      prevSurveyUrl,
    } = this.props;

    return (
      <div className="view text">
        <div className="cnt-wrapper">
          <QuestionMeta
            questionOrd={question.ord}
            questionsCount={questionsCount}
            isGatewaySurvey={isGatewaySurvey}
          />

          <h4 className="label">{question.label}</h4>

          <input
            onChange={(e) => this.handleChange(e.target.value)}
            placeholder={question.settings.placeholder}
            value={value}
            type="text"
          />

          <QuestionButtons
            prevSurveyUrl={prevSurveyUrl}
            prevQuestion={prevQuestion}
            isNextDisabled={isNextDisabled}
            nextQuestion={nextQuestion}
          />
        </div>
      </div>
    );
  }
}

export default SingleLineTextView;
