import React from 'react';
import { ListInput, TextInput } from '@raketa-cms/raketa-cms';

const SimpleListItem = ({ settings, onChangeItem }) => (
  <TextInput
    label="Title"
    onChange={value => onChangeItem('title', value)}
    value={settings.title}
  />
);

const SimpleList = ({ settings, onChange }) => (
  <div className="form-group">
    <label className="control-label">Answers</label>
    <ListInput
      listItem={(itemSettings, onChangeItem) =>
        <SimpleListItem settings={itemSettings} onChangeItem={onChangeItem} />}
      onChangeList={(_, items) => onChange(items)}
      items={settings}
    />
  </div>
);

export default SimpleList;
