import React from 'react';
import jQuery from 'jquery';
import Autocomplete from 'react-autocomplete';

class TokenInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      q: '',
      items: props.items || [],
      results: [],
    };
  }

  handleSearch(q) {
    const { searchUrl } = this.props;

    this.setState({ q }, () => {
      if (q.length < 3) return;

      jQuery.get(searchUrl, { q }, results => {
        this.setState({ results });
      }, 'json');
    });
  }

  notifyChange() {
    const { onChange } = this.props;
    if (onChange) onChange(this.state.items);
  }

  handleAdd(item) {
    const { items } = this.state;

    if (items.findIndex(i => i.id === item.id) !== -1) return;

    const newItems = [
      ...items,
      item,
    ];

    this.setState({ items: newItems, q: '', results: [] });
  }

  handleRemove(item) {
    if (!confirm('Are you sure?')) return;

    const { items } = this.state;
    const idx = items.findIndex(i => i.id === item.id);

    const newItems = [
      ...items.slice(0, idx),
      ...items.slice(idx + 1),
    ];

    this.setState({ items: newItems });
  }

  showErrors() {
    return this.props.errors.length > 0;
  }

  render() {
    const { label, name } = this.props;
    const { q, results, items } = this.state;

    const menuStyle = {
      borderRadius: '3px',
      boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
      background: '#fff',
      position: 'fixed',
      overflow: 'auto',
      maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
    };

    return (
      <div className="token-input">
        <div className={"form-group" + (this.showErrors() ? ' has-error' : '')}>
          <label className="control-label">{label}</label>
          <Autocomplete
            inputProps={{ type: 'text', className: 'form-control' }}
            wrapperStyle={{}}
            getItemValue={item => item.name}
            items={results}
            renderMenu={(items, value, style) => (
              <div>
              {items.length > 0 &&
                <div style={{ ...style, ...menuStyle }} children={items} />
              }

              {items.length < 1 &&
                <div style={{ ...style, ...menuStyle }} className="no-results">
                  <div className="autocomplete-result">
                    Not found.
                  </div>
                </div>
              }
              </div>
            )}
            renderItem={(item, isHighlighted) =>
              <div key={item.id} className="autocomplete-result" style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                <strong>{item.name}</strong>
              </div>
            }
            value={q}
            onChange={e => this.handleSearch(e.target.value)}
            onSelect={(q, item) => this.handleAdd(item, () => this.notifyChange())}
            menuStyle={menuStyle}
          />
          {this.showErrors() &&
            <span className="help-block">{this.props.errors.join(', ') }</span>
          }

          <ul className="token-list">
            {items.map((item, idx) =>
              <li className="token-item" key={item.id}>
                <span>{item.name}</span>
                <button type="button" onClick={() => this.handleRemove(item)}>&times;</button>

                <input type="hidden" name={`${name}[]`} value={item.id} />
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default TokenInput;
