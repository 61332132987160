import React from 'react';
import Intro from './Intro';
import BoxListView from './BoxListView';
import SimpleListView from './SimpleListView';
import TextView from './TextView';
import SingleLineTextView from './SingleLineTextView';
import RangeView from './RangeView';
import SubmitView from './SubmitView';
import FinishView from './FinishView';
import jQuery from 'jquery';

class Survey extends React.Component {
  static questionViews = {
    box_list: BoxListView,
    simple_list: SimpleListView,
    text: TextView,
    single_line: SingleLineTextView,
    range: RangeView,
  };

  constructor(props) {
    super(props);

    const { introText, introImage, skipIntro, refererUrl, isGateway } = props.survey;

    this.state = {
      currentViewIdx: skipIntro ? 1 : 0,
      ticket: {
        session: this.props.session,
        category_id: this.props.category,
        customer_email: '',
        survey: [],
        source: 'source',
      }
    };

    const introBackUrl = !isGateway && !skipIntro && refererUrl ? refererUrl : false

    this.views = [
      <Intro
        ambassadorsImage={introImage}
        introText={introText}
        onClick={() => this.setState({ currentViewIdx: this.state.currentViewIdx + 1 })}
        introBackUrl={introBackUrl}
      />,
      ...this.questionsToViews(props.questions),
      <SubmitView
        onChange={email => this.handleEmailChange(email)}
        onClick={() => { this.sendSurvey(); this.setState({ currentViewIdx: this.state.currentViewIdx + 1 }); }}
      />,
      <FinishView />
    ];
  }

  handlePrevQuestion() {
    this.setState({ currentViewIdx: this.state.currentViewIdx - 1 });
  }

  handleNextQuestion() {
    this.setState({ currentViewIdx: this.state.currentViewIdx + 1 });
  }

  handleBoxListChange(questionSettings) {
    const { ticket } = this.state;
    const { survey } = ticket;

    const questionIdx = survey.findIndex(item => item.question_id === questionSettings.settings.question_id);

    if (questionIdx > -1) survey.splice(questionIdx, 1);

    survey.push(questionSettings.settings);

    const newTicket = Object.assign({}, ticket, { survey: survey });
    this.setState({ ticket: newTicket });
  }

  handleQuestionChange(questionSettings, currentQuestion) {
    const { ticket } = this.state;
    const { survey } = ticket;

    if (currentQuestion.question_type === 'box_list') {
      this.handleBoxListChange(questionSettings);
      return;
    }

    const questionIdx = survey.findIndex(item => item.question_id === questionSettings.question_id);
    if (questionIdx > -1) survey.splice(questionIdx, 1);

    survey.push(questionSettings);

    const newTicket = Object.assign({}, ticket, { survey: survey });
    this.setState({ ticket: newTicket });
  }

  handleEmailChange(newEmail) {
    const { ticket } = this.state;

    const newTicket = Object.assign({}, ticket, { customer_email: newEmail });
    this.setState({ ticket: newTicket });
  }

  setQuestionSetttings(question, survey) {
    if (survey.length <= 0) return;

    return survey.filter(answer => question.id === answer.question_id)[0];
  }

  questionsToViews(questions) {
    const { isGateway, refererUrl, skipIntro } = this.props.survey;
    const { ticket } = this.state;
    const { survey } = ticket;
    const orderedQuestions = questions.sort((a, b) => a.ord - b.ord);

    return orderedQuestions.map((question, idx) => {
      const Component = Survey.questionViews[question.question_type];
      return (
        <Component
          question={question}
          survey={survey}
          nextQuestion={() => this.handleNextQuestion()}
          prevQuestion={() => this.handlePrevQuestion()}
          questionsCount={orderedQuestions.length}
          onChange={questionSettings => this.handleQuestionChange(questionSettings, question)}
          questionSettings={() => this.setQuestionSetttings(question, survey)}
          isGatewaySurvey={isGateway}
          prevSurveyUrl={!isGateway && refererUrl && skipIntro && idx === 0 ? refererUrl : false}
        />);
    });
  }

  sendSurvey() {
    jQuery.ajax({
      contentType: 'application/json',
      data: JSON.stringify({ ticket: this.state.ticket }),
      dataType: 'json',
      type: 'POST',
      url: '/api/ticket'
    });
  }

  render() {
    const { currentViewIdx } = this.state;

    return (
      <div className="survey">
        {this.views[currentViewIdx]}
      </div>
    );
  }
}

export default Survey;
