import React from 'react';
import Autocomplete from 'react-autocomplete';
import jQuery from 'jquery';

class TicketAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      q: '',
      dirty: false,
    };
  }

  handleProductSearch(q) {
    this.setState({ q, products: [] }, () => {
      if (q.length < 3) {
        this.setState({ dirty: false });
        return;
      }
      jQuery.get('/tickets/products.json?', { q }, (products) => {
        this.setState({ products });

        this.setState({ dirty: true });
      }, 'json');
    });
  }

  handleProductSelect(product, productName) {
    const { settings, onChangeProduct } = this.props;

    onChangeProduct(product);

    this.setState({ q: productName });
  }

  render() {
    const { settings } = this.props;
    const { q, products, dirty } = this.state;

    const menuStyle = {
      background: '#fff',
      position: 'absolute',
      top: 'auto',
      overflowY: 'auto',
      left: '0',
      right: '0',
      color: '#000',
      border: '1px solid #ddd',
      borderTop: 'none',
      maxHeight: '300px',
      zIndex: '5',
    };

    return (
      <div className="autocomplete">
        <Autocomplete
          getItemValue={item => item.title}
          items={products}
          renderMenu={(items, value, style) => (
            <div style={{ ...style, ...menuStyle }}>
              {items}
              {products.length === 0 && dirty === true && <div style={{ padding: '1em' }}>No results found.</div>}
            </div>
          )}
          renderItem={(item, isHighlighted) =>
            <div key={item.id} style={{ display: 'flex', alignItems: 'center', padding: '0.5em', cursor: 'pointer', background: isHighlighted ? '#e0e0e0' : '#fff' }}>
              <img src={item.image_url} alt=""/> {item.title}
            </div>
          }
          value={q}
          onChange={e => this.handleProductSearch(e.target.value)}
          onSelect={(productName, product) => { this.handleProductSelect(product, productName); this.setState({ q: '' }) }}
          wrapperStyle={{ display: 'inline-block', width: '100%' }}
          menuStyle={menuStyle}
          inputProps={{ placeholder: 'Search for products' }}
        />
      </div>
    );
  }
};

export default TicketAutocomplete;
