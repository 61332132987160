import React from 'react';

const QuestionSelect = ({ questionTypes, questionType, onChange }) => (
  <div className="form-group select required question_question_type">
    <label className="control-label select required" htmlFor="question_question_type">Question type</label>
    <select
      className="form-control select required"
      id="question_question_type"
      value={questionType}
      onChange={(e) => onChange(e.target.value)}
    >
      {questionTypes.map((option, idx) => (<option key={idx} value={option[1]}>{option[0]}</option>))}
    </select>
  </div>
);

export default QuestionSelect;
