import React, { useState } from 'react';
import { ReactSortable } from "react-sortablejs";

const post = (url, params = {}, opts = {}) => fetch(url, Object.assign({ method: 'POST', credentials: 'same-origin', body: JSON.stringify(params) }, opts))

const ProductForm = ({ onChange, categoriesUrls }) => {
  const [url, setURL] = useState('');

  const importProduct = (url) => {
    const body = new FormData();
    body.append('parse[url]', url);

    const token = document.querySelector('meta[name="csrf-token"]');

    post('/tickets/parse', {}, { body, headers: { 'X-CSRF-Token': token } })
      .then(r => r.json())
      .then(product => {
        onChange(product)
        setURL('');
      });
  }

  return (
    <div className="product-form">
      <div className="form-group">
        <label className="control-label">Product URL</label>
        <input type="text" className="form-control" value={url} onChange={e => setURL(e.target.value)} />
        <div className="hint">Paste an URL from: <br />
          {categoriesUrls.map((category, idx) => (
            <span key={idx}>
              {idx === 0 ? '' : ', '}<a href={category.href} target="_blank">{category.name}</a>
            </span>
          ))}
        </div>
      </div>

      <button type="button" className="waves-effect waves-light btn" disabled={!url} onClick={() => importProduct(url)}>Import product</button>
    </div>
  );
}

const decathlonImgUrl = (imgUrl) => {
  try {
    const url = new URL(imgUrl);

    if (url.hostname || url.protocol) {
      return imgUrl;
    } else {
      return `https://decathlon.bg${imgUrl}`;
    }
  }
  catch (err) {
    return `https://decathlon.bg${imgUrl}`;
  }
};

const Product = ({ recommendation, ord, type, ticketId, onNoteChange, onDelete }) => (
  <div className="product-item" key={recommendation.id}>
    <div className="product-inner">
      <img src={decathlonImgUrl(recommendation.product.image_url)} alt={recommendation.product.title} />
      <div className="product-info">
        <h6><a href={recommendation.product.url} target="_blank">{recommendation.product.title}</a></h6>

        <div className="form-group">
          <label className="control-label">Note</label>
          <textarea className="form-control" value={recommendation.note} onChange={(e) => onNoteChange(e)} />
        </div>
      </div>
    </div>

    <button type="button" className="waves-effect waves-light btn red" onClick={() => onDelete()}>Delete</button>

    <input type="hidden" name="recommendation[recommended_products][]recommended_product[product_id]" value={recommendation.product.id} />
    <input type="hidden" name="recommendation[recommended_products][]recommended_product[product_type]" value={type} />
    <input type="hidden" name="recommendation[recommended_products][]recommended_product[ticket_id]" value={ticketId} />
    <input type="hidden" name="recommendation[recommended_products][]recommended_product[note]" value={recommendation.note} />
    <input type="hidden" name="recommendation[recommended_products][]recommended_product[ord]" value={ord} />
  </div>
);

const ProductList = ({ type, ticketId, recommendations, onChange, onDelete, setItems }) => {
  return (
    <div className="product-list">
      <ReactSortable tag="div" list={recommendations} setList={setItems}>
        {recommendations.map((recommendation, idx) =>
          <Product
            key={recommendation.id}
            ord={idx}
            type={type}
            ticketId={ticketId}
            recommendation={recommendation}
            onNoteChange={(e) => {
              const idx = recommendations.findIndex(p => p.id === recommendation.id);

              onChange([
                ...recommendations.slice(0, idx),
                Object.assign({}, recommendation, { note: e.target.value }),
                ...recommendations.slice(idx + 1),
              ]);
            }}
            onDelete={() => {
              if (!confirm('Are you sure?')) return;

              const idx = recommendations.findIndex(p => p.id === recommendation.id);

              onDelete([
                ...recommendations.slice(0, idx),
                ...recommendations.slice(idx + 1),
              ]);
            }}
          />
        )}
      </ReactSortable>
    </div>
  )
};

export default ({ ticketId, categoriesUrls, upsaleCategoriesUrls }) => {
  const [recommendations, setRecommendations] = useState([]);
  const [upsales, setUpsales] = useState([]);
  const [intro, setIntro] = useState('');

  return (
    <div className="recommendation-form">
      <div className="form-group">
        <label className="control-label">Introduction</label>
        <textarea className="form-control" value={intro} onChange={(e) => setIntro(e.target.value)} />
      </div>

      <h5>Products</h5>

      <ProductForm
        onChange={product => {
          if (recommendations.find(p => p.id === product.id)) return;
          setRecommendations([...recommendations, { id: product.id, note: '', product }])
        }}
        categoriesUrls={categoriesUrls}
      />

      <ProductList
        type="primary"
        ticketId={ticketId}
        recommendations={recommendations}
        onChange={recommendations => setRecommendations(recommendations)}
        onDelete={recommendations => setRecommendations(recommendations)}
        setItems={recommendations => setRecommendations(recommendations)}
      />

      <h5>Upsale Products</h5>

      <ProductForm
        onChange={product => {
          if (upsales.find(p => p.id === product.id)) return;
          setUpsales([...upsales, { id: product.id, note: '', product }])
        }}
        categoriesUrls={upsaleCategoriesUrls}
      />

      <ProductList
        type="accessory"
        ticketId={ticketId}
        recommendations={upsales}
        onChange={upsales => setUpsales(upsales)}
        onDelete={upsales => setUpsales(upsales)}
        setItems={upsales => setUpsales(upsales)}
      />

      <input type="hidden" name="recommendation[introduction]" value={intro} />

      <button type="submit" className="waves-effect waves-light btn" disabled={recommendations.length === 0}>Submit</button>
    </div>
  );
};
