import React, { useState } from 'react';
import QuestionMeta from './QuestionMeta';
import Slider from 'rc-slider';
import { disableNext } from './utils';
import QuestionButtons from './QuestionButtons';

const valueWithUnit = (value, question) => question.settings.unit ? `${value} ${question.settings.unit}` : value;

const RangeView = ({ questionSettings, question, nextQuestion, prevQuestion, questionsCount, onChange, isGatewaySurvey, prevSurveyUrl }) => {
  const [isNextDisabled, setIsNextDisabled] = useState(disableNext(question, questionSettings));
  const [value, setValue] = useState(questionSettings() ? questionSettings().answer : 0);
  const marks = {
    [question.settings.min]: <strong className="label">{valueWithUnit(question.settings.min, question)}</strong>,
    [question.settings.max]: <strong className="label">{valueWithUnit(question.settings.max, question)}</strong>
  };

  return (
    <div className="view range">
      <div className="cnt-wrapper">
        <QuestionMeta
          questionOrd={question.ord}
          questionsCount={questionsCount}
          isGatewaySurvey={isGatewaySurvey}
        />

        <h4 className="label">{question.label}</h4>

        <div className="range-slider">
          <Slider
            onChange={value => {
              setValue(value);
              setIsNextDisabled(false);
              onChange({
                question_label: question.label,
                answer: value,
                question_id: question.id
              });
            }}
            min={question.settings.min || 0}
            max={question.settings.max || 100}
            step={1}
            defaultValue={value}
            marks={marks}
          />

          <h4>{valueWithUnit(value, question)}</h4>
        </div>

        <QuestionButtons
          prevSurveyUrl={prevSurveyUrl}
          prevQuestion={prevQuestion}
          isNextDisabled={isNextDisabled}
          nextQuestion={nextQuestion}
        />
      </div>
    </div>
  );
};

export default RangeView;
